import { Button, Form, Input, InputNumber, Modal, ModalProps, Select, Typography } from "antd"
import { TPool } from "../../models/pool"
import { FC } from "react"
import { EInputErrorMessages } from "../../models/InputErrorMessages";
import useStores from "../../hooks/useStores";
import { usePopup } from "../../hooks/usePopup";
import bn from 'bignumber.js'

type CompProps = {
  item: TPool,
  onSuccess: () => Promise<void>
} & ModalProps

type FieldType = {
  assetId: string,
  amount: number,
};

export const SwapModal: FC<CompProps> = ({ item, onSuccess, ...rest }) => {
  const { signStore, configStore } = useStores()
  const assets = [
    { label: configStore.getAssetNameById(item.baseAssetId), value: item.baseAssetId },
    { label: configStore.getAssetNameById(item.quoteAssetId), value: item.quoteAssetId }
  ]
  const [form] = Form.useForm()
  const { message } = usePopup()

  const onFinish = async (values: FieldType) => {
    try {
      const { assetId, amount } = values
      const params = signStore.swapParams({
        poolId: item.id,
      })
      const payments = [
        {
          assetId: assetId,
          amount: amount * 1e8
        },
      ]
      await signStore.callContract({ params, payments })
      message.info('Swap transaction successfully sent')
      await onSuccess()
    } catch (e: any) {
      const text = e.response.data.errorMessage
      const error = text.slice(text.indexOf('[') + 1, text.indexOf(']') - 1)
      message.error(error)
    }
    form.resetFields()
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    form.resetFields()
    rest?.onCancel?.(e)
  };

  const onValuesChange = ({ assetId, amount }: FieldType) => {
    if (assetId) {
      form.setFieldValue("for", assetId === item.baseAssetId ? item.quoteAssetId : item.baseAssetId)
    }
    if (assetId && amount) {
      const fee = bn(amount).multipliedBy(Number(item.feeRate) / 1e8)
      const amountWithFee = bn(amount).minus(fee).multipliedBy(1e8)

      const result = signStore.calculateSwap(item, assetId, amountWithFee)
      form.setFieldValue("recieved", Number(result.baseAssetAmount.dividedBy(1e8)))
    }
  }

  return <Modal
    {...rest}
    title='Swap asset'
    onCancel={onCancel}
    footer={[
      <Button key='decline' onClick={onCancel} type="primary" danger>Cancel</Button>,
      <Button key='accept' type="primary" htmlType="submit" form="swapForm">Swap</Button>
    ]}
  >
    <Form
      id="swapForm"
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      onValuesChange={(_, values) => onValuesChange(values)}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 18 }}
      labelAlign="right"
    >
      <Form.Item<FieldType>
        label="Swap"
        name="assetId"
        rules={[{ required: true, message: EInputErrorMessages.REQUIRED }]}
      >
        <Select placeholder='Select asset' style={{ width: '100%' }} options={assets}/>
      </Form.Item>
      <Form.Item<FieldType>
        label="Amount"
        name="amount"
        rules={[{ required: true, message: EInputErrorMessages.REQUIRED }]}
      >
        <InputNumber style={{ width: '100%' }}/>
      </Form.Item>
      <Form.Item
        label='For'
        name="for"
      >
        <Select style={{ width: '100%' }} options={assets} disabled/>
      </Form.Item>
      <Form.Item
        label='Recieve'
        name="recieved"
      >
        <InputNumber style={{ width: '100%' }} disabled/>
      </Form.Item>
      <Form.Item
        label='Fee'
      >
        <Typography.Text className="ant-form-text" type="secondary">
          {`${Number(item.feeRate) / 1e6}%`}
        </Typography.Text>
      </Form.Item>
    </Form>

  </Modal>
}
