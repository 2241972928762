import { EOperation } from "./operations";
import { TUser } from "./user";

export enum ERequestStatus {
  APPROVING = 'APPROVING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED'
}

export interface IRequest {
  timestamp: number,
  amount: string
  assetId: string
  id: string
  status: ERequestStatus
  targetAddress : string
  type: EOperation
}

export type IRequestWithUserData = IRequest & Omit<TUser, 'firstname' | 'lastname'> & {
  clientName: string
}