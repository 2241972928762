export enum ERequestAction {
  CREATE_REQUEST = 'createRequest',
  REJECT_REQUEST = 'rejectRequest',
  APPROVE_REQUEST = 'approveRequest',
}

export enum EPoolAction {
  CREATE_POOL = 'createPool',
  SWAP = 'swap',
  ADD_LIQUIDITY = 'addLiquidity',
}