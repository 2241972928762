import { Button, Form, Input, InputNumber, Modal, ModalProps, Select, Typography } from "antd"
import { FC, useEffect, useState } from "react"
import { EInputErrorMessages } from "../../models/InputErrorMessages"
import { getSpacifiedNumber, mapAssetsToSelectOptions } from "../../utils"
import useStores from "../../hooks/useStores"
import { TransferFieldType } from "."

type CompProps = ModalProps & {
  onFinish: (values: TransferFieldType) => Promise<void>
  balances: Record<string, number>
}


export const TransferModal: FC<CompProps> = (props) => {
  const { configStore } = useStores()
  const [form] = Form.useForm()
  const assets = mapAssetsToSelectOptions(configStore.assets)
  const [availableBalance, setAvailableBalance] = useState(0)

  useEffect(() => {
    if (Object.keys(props.balances).length) {
      setAvailableBalance(props.balances[assets[0].value])
    }
  }, [props.balances])

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    form.resetFields()
    props?.onCancel?.(e)
  };

  const onSelectChange = (value: string) => {
    form.setFieldValue("assetId", value)
    setAvailableBalance(props.balances[value])
  }

  const onFinish = async (values: TransferFieldType) => {
    await props.onFinish(values)
    form.resetFields()
  }

  return (
    <Modal {...props}
      title='Currency Transfer'
      onCancel={onCancel}
      footer={[
        <Button key='decline' onClick={onCancel} type="primary" danger>Cancel</Button>,
        <Button key='accept' type="primary" htmlType="submit" form='transferForm'>Transfer</Button>
      ]}
    >
      <Form 
        id='transferForm'
        form={form}
        onFinish={onFinish} 
        autoComplete="off"
        initialValues={{ assetId: assets[0].value }}
      >
        <Form.Item<TransferFieldType>
          label="Asset"
          name="assetId"
          rules={[{ required: true, message: EInputErrorMessages.REQUIRED }]}
        >
          <Select options={assets} onChange={onSelectChange} />
        </Form.Item>
        <Form.Item
          label='Available Balance'
        >
          <Typography.Text className="ant-form-text" type="secondary">
            {getSpacifiedNumber(availableBalance / 1e8)}
          </Typography.Text>
        </Form.Item>
        <Form.Item<TransferFieldType>
          label="Amount"
          name="amount"
          rules={[{ required: true, message: EInputErrorMessages.REQUIRED }]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item<TransferFieldType>
          label="Reciever Address"
          name="recipient"
          rules={[{ required: true, message: EInputErrorMessages.REQUIRED }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}