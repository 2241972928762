import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import React, { Suspense, useCallback } from 'react'
import { AppRoutes, routeConfig } from './routeConfig'
import { AppRoutesProps } from './types'
import { RequireAuth } from './RequireAuth'
import { observer } from 'mobx-react-lite'
import { Layout } from '../Layout'
import useStores from '../hooks/useStores'

export const AppRouter = observer(() => {
  const { authStore } = useStores()
  const { isAdmin } = authStore
  const defaultPath = isAdmin ? AppRoutes.DEPOSIT_REQUESTS : AppRoutes.WALLETS
  const renderWithWrapper = useCallback((route: AppRoutesProps) => {
    const element = (
        route.authOnly ?
          <RequireAuth>
            {route.element}
          </RequireAuth> :
          route.element
    )

    return (
      <Route key={route.path} element={!route.withoutLayout && <Layout />}>
        <Route
          path={route.path}
          element={element}
        >
        </Route>
      </Route>
    )
  }, [])

  return <Routes>
    <Route path="/" element={<Navigate to={defaultPath} />} />
    {Object.values(routeConfig).map(renderWithWrapper)}
  </Routes>
})