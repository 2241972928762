import { makeAutoObservable, runInAction } from 'mobx'
import decodeJWT, { JwtPayload } from 'jwt-decode'
import { Api } from '../api'
import { ITokenPair } from '../api/ApiInterfaces'
import { TUser } from '../models/user'
import ConfigStore from './ConfigStore'

const tokensLocalStorageKey = 'tokenPair'

type WEJWTPayload = {
  id: string,
  name: string,
} & JwtPayload

enum Roles {
  USER = 'user',
  TRANBORDER_ADMIN = 'transborder_admin'
}

export default class AuthStore {
  api: Api
  configStore: ConfigStore
  isLoggedIn = false
  isWalletAvailable = false
  walletState = {}

  id = ''
  email = ''
  password = ''
  roles: Roles[] = []
  userData: TUser = {
    id: '',
    firstname: '',
    lastname: '',
    address: '',
    publicKey: '',
    companyName: '',
    bankId: '',
    bankClientId: ''
  }

  constructor(api: Api, configStore: ConfigStore) {
    this.api = api
    this.configStore = configStore

    makeAutoObservable(this)
  }

  async loadUserData() {
    const userData = await this.api.getUserByAddress(this.configStore.address)
    runInAction(() => {
      this.userData = userData
    })
  }

  async loadUserProfile() {
    const profile = await this.api.getUserProfile()

    this.roles = profile.roles
  }

  get isAdmin() {
    return this.roles.includes(Roles.TRANBORDER_ADMIN)
  }

  createRefresherAxios(tokenPair: ITokenPair, onRefreshFailed: () => void) {
    return this.api.createAxiosWithRefresher(tokenPair, onRefreshFailed)
  }

  signIn(username: string, password: string): Promise<ITokenPair> {
    return this.api.signIn(username, password)
  }

  signUp(username: string, password: string): Promise<ITokenPair> {
    return this.api.signUp(username, password)
  }

  logout(): void {
    this.deleteTokenPair()
    this.setLoggedIn(false)
  }

  loginWithTokenPair(tokenPair: ITokenPair): void {
    const { id, name }: WEJWTPayload = decodeJWT(tokenPair.access_token)
    this.id = id
    this.email = name
    this.writeTokenPair(tokenPair)
  }

  setPassword(password: string): void {
    this.password = password
  }

  setLoggedIn(isLoggedIn: boolean): void {
    this.isLoggedIn = isLoggedIn
  }

  writeTokenPair(tokenPair: ITokenPair): void {
    window.localStorage.setItem(tokensLocalStorageKey, JSON.stringify(tokenPair))
  }

  deleteTokenPair(): void {
    window.localStorage.removeItem(tokensLocalStorageKey)
  }

  readTokenPair(): ITokenPair | null {
    const data = window.localStorage.getItem(tokensLocalStorageKey)
    if (data) {
      try {
        return JSON.parse(data)
      } catch (e) {
        return null
      }
    }
    return null
  }
}
