import Sider from 'antd/es/layout/Sider';
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { adminMenuConfig } from "./adminMenuConfig";
import { clientMenuConfig } from "./clientMenuConfig";
import { Menu, MenuProps } from 'antd';
import { useState } from 'react';
import { AppRoutes } from '../../router/routeConfig';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks/useStores';

const SidebarWrapper = styled(Sider)`
  height: 100vh;
  color: #fff;
`

const siderStyle: React.CSSProperties = {
  textAlign: 'center',
  backgroundColor: '#fff',
  boxShadow: '8px 0px 10px rgba(0, 0, 0, 0.06)'
};

export const Sidebar = observer(() => {
  const { authStore } = useStores()
  const { isAdmin } = authStore
  const [current, setCurrent] = useState<AppRoutes>(AppRoutes.DEPOSIT_REQUESTS);

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key as AppRoutes);
  };

  const menuConfig = isAdmin ? adminMenuConfig : clientMenuConfig
  const items: MenuProps['items'] = menuConfig.map(({text, path}) => ({
    label: (
      <NavLink to={path}>{text}</NavLink>
    ),
    key: path,
  }))
  
  return <SidebarWrapper style={siderStyle}>
    <Menu mode="vertical" items={items} selectedKeys={[current]} onClick={onClick} />
  </SidebarWrapper>
})