import crossFetch from 'cross-fetch'
import { TokenRefresherConfig } from './types'
import { TokenRefresher } from './refresher'
import { AxiosInstance, AxiosRequestConfig, default as axiosCostructor } from 'axios'

export class ApiTokenRefresher {
  private readonly axiosRequestConfig?: AxiosRequestConfig
  private readonly tokenRefresher: TokenRefresher

  constructor(config: TokenRefresherConfig) {
    this.axiosRequestConfig = config.axiosRequestConfig
    this.tokenRefresher = new TokenRefresher(config)
  }

  init() {
    return {
      axios: this.getAxios(),
      fetch: this.fetch,
    }
  }

  private getAxios = (): AxiosInstance => {
    const axios = axiosCostructor.create(this.axiosRequestConfig)

    axios.interceptors.request.use(async (config) => {
      const accessToken = await this.tokenRefresher.getAccessToken()
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      }
      return config
    })

    return axios
  }

  private fetch = async (url: RequestInfo, requestInit: RequestInit = { headers: {} }): Promise<Response> => {
    const accessToken = await this.tokenRefresher.getAccessToken()
    const initOptions = {
      ...requestInit,
      headers: {
        ...(requestInit.headers || {}),
        Authorization: `Bearer ${accessToken}`,
      },
    }

    return crossFetch(url, initOptions)
  }
}
