
import { Content as ContentAntd } from 'antd/es/layout/layout';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const ContentWrapper = styled(ContentAntd)`
  height: 100vh;
  padding: 30px;
`

export const Content: FC<PropsWithChildren> = ({children}) => {
  return <ContentWrapper>{children}</ContentWrapper>
}