import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Navigate } from 'react-router-dom'
import useStores from '../hooks/useStores'
import { AppRoutes } from './routeConfig'

type ProtectedRouteProps = {
  children: JSX.Element,
}

export const RequireAuth: FC<ProtectedRouteProps> = observer(({ children }) => {
  const { authStore } = useStores()
  const { isLoggedIn  } = authStore

  if (!isLoggedIn) {
    return <Navigate to={`/${AppRoutes.SIGN_IN}`} />
  }

  return children
})