import axios, { AxiosInstance } from 'axios'
import { ApiTokenRefresher } from '@wavesenterprise/api-token-refresher'
import { ITokenPair, ITransaction, TContractState } from './ApiInterfaces'
import { TAsset } from '../models/asset'
import { TAssetBalance } from '../models/balance'
import { TCrateUser, TUser } from '../models/user'

const AUTH_SERVICE_ADDRESS = '/authServiceAddress/v1'
const NODE_ADDRESS = '/nodeAddress'
const API_ADDRESS = '/apiAddress'
const KEYS_ADDRESS = '/keysAddress'

export class Api {
  private _unauthorizedClient: AxiosInstance = axios.create()
  private _apiClient!: AxiosInstance

  createAxiosWithRefresher(tokenPair: ITokenPair, onRefreshFailed: () => void) {
    const refreshCallback = async (token: string) => {
      try {
        const { data } = await axios.post(`${AUTH_SERVICE_ADDRESS}/auth/refresh`, { token })
        // console.log('JWT token refreshed')
        return data
      } catch (e) {
        console.error('JWT token refresh failed:', (e as Error).message)
        onRefreshFailed()
      }
    }

    const apiTokenRefresher: ApiTokenRefresher = new ApiTokenRefresher({
      maxAttemptsToRefreshToken: 1,
      authorization: tokenPair,
      refreshCallback,
    })
    return apiTokenRefresher.init()
  }

  setupApi(refresherAxios: any) {
    this._apiClient = refresherAxios
  }

  // Auth requests
  signIn = async (username: string, password: string): Promise<ITokenPair> => {
    const { data: tokenPair } = await this._unauthorizedClient.post(`${AUTH_SERVICE_ADDRESS}/auth/login`, { username, password })
    return tokenPair
  }

  signUp = async (username: string, password: string): Promise<ITokenPair> => {
    const { data } = await this._unauthorizedClient.post(`${AUTH_SERVICE_ADDRESS}/user`, { username, password })
    return data
  }

  getUserProfile = async () => {
    const { data } = await this._apiClient.get(`${AUTH_SERVICE_ADDRESS}/user/profile`)
    return data
  }

  // Keys requests
  createKey = async (name: string) => {
    const { data: { result } } = await this._apiClient.post(`${KEYS_ADDRESS}/keys/${name}`)
    return result
  }

  getKey = async (name: string) => {
    const { data: { result } } = await this._apiClient.get(`${KEYS_ADDRESS}/keys/${name}`)
    return result
  }

  broadcast = async (keyName: string, tx: any) => {
    const { data } = await this._apiClient.post(`${KEYS_ADDRESS}/keys/${keyName}/broadcast`, tx)
    return data
  }

  broadcastTx = async (keyName: string, tx: any) => {
    const { data } = await this._apiClient.post(`${KEYS_ADDRESS}/keys/${keyName}/broadcastTx`, tx)
    return data
  }

  // Node requests
  getKeysValuesByContractId = async (contractId: string, matches: string, limit: number = 1000): Promise<TContractState[]> => {
    const { data } = await this._apiClient.get(`${NODE_ADDRESS}/contracts/${contractId}?matches=${matches}&limit=${limit}`)
    return data
  }

  getTransactionsByAddress = async (address: string, limit: number = 1000): Promise<ITransaction[]> => {
    const { data } = await this._apiClient.get(`${NODE_ADDRESS}/transactions/address/${address}/limit/${limit}`)
    return data[0]
  }

  getAssetDetails = async (assetId: string): Promise<TAsset> => {
    const { data } = await this._apiClient.get(`${NODE_ADDRESS}/assets/details/${assetId}`)
    return data
  }

  getAssetsBalanceByAddress = async (address: string): Promise<TAssetBalance[]> => {
    const { data } = await this._apiClient.get(`${NODE_ADDRESS}/assets/balance-v2/${address}`)
    return data.balances
  }

  //Api requests
  getUsers = async (): Promise<TUser[]> => {
    const { data } = await this._apiClient.get(`${API_ADDRESS}/users`)
    return data
  }

  getUserByAddress = async (address: string): Promise<TUser> => {
    const { data } = await this._apiClient.get(`${API_ADDRESS}/users/${address}`)
    return data
  }

  createUser = async (user: Omit<TUser, 'id'>) => {
    const { data } = await this._apiClient.post(`${API_ADDRESS}/users`, { ...user })
    return data
  }

}
