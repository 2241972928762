import { makeAutoObservable, runInAction } from 'mobx'
import { Api } from '../api'

export default class ConfigStore {
  api: Api
  assets: Record<string, string> = {}
  contractAddress = 'DaGovKAf4WJDm1frfH5ETPBJLjArJ5wxvwBeofGzrPdS'

  keyName = 'transborder'
  address = ''
  publicKey = ''

  constructor(api: Api) {
    this.api = api

    makeAutoObservable(this)
  }

  getAssetNameById(assetId: string) {
    return this.assets[assetId]
  }

  async init() {
    try {
      const { address, publicKey } = await this.api.getKey(this.keyName)
      runInAction(() => {
        this.address = address
        this.publicKey = publicKey
      })
      return { address, publicKey }
    } catch (e) {
      const { address, publicKey } = await this.api.createKey(this.keyName)
      runInAction(() => {
        this.address = address
        this.publicKey = publicKey
      })
      return { address, publicKey }
    }
  }

  async getAssetsBalancesByAddress(address: string) {
    try {
      const balances = await this.api.getAssetsBalanceByAddress(address)
      const normalizedBalances = balances.reduce((acc, cur) => {
        const { assetId, balance } = cur
        return {
          ...acc,
          [assetId]: balance,
        }
      }, {} as Record<string, number>)
      const balancesByAssets = Object.keys(this.assets)
        .map(assetId => ({ assetId, balance: normalizedBalances[assetId] || 0 }))
      return balancesByAssets.reduce((acc, cur) => {
        const { assetId, balance } = cur
        return {
          ...acc,
          [assetId]: balance,
        }
      }, {} as Record<string, number>)
    } catch (e: any) {
      console.error('Error on getting east balance', e.message)
      return {}
    }
  }

  async loadAssets() {
    try {
      const assets = await this.api.getKeysValuesByContractId(this.contractAddress, 'ASSET.*')
      const assetsIds = assets.map(({ key }) => key.split('_')[1])
      const assetsDetailsPromises = assetsIds.map(el => this.api.getAssetDetails(el))
      const result = await Promise.all(assetsDetailsPromises)
      const normalizedAssets = result.reduce((acc, cur) => {
        const { assetId, name } = cur
        return {
          ...acc,
          [assetId]: name,
        }
      }, {} as Record<string, string>)
      runInAction(() => {
        this.assets = normalizedAssets
      })
    } catch (e) {
      console.error(e);
    }
  }
}
