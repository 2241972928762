import { RouteProps } from 'react-router-dom'
import { SignInPage } from '../pages/Auth/SignIn'
import { WalletsPage } from '../pages/WalletsPage'
import { TransactionsPage } from '../pages/TransactionsPage'
import { ExchangePage } from '../pages/ExchangePage'
import { SignUpPage } from '../pages/Auth/SignUp'
import { RequestsPage } from '../pages/RequestsPage'
import { EOperation } from '../models/operations'

export type AppRoutesProps = RouteProps & {
  authOnly?: boolean,
  element: JSX.Element,
  withoutLayout?: boolean,
}

export enum AppRoutes {
  SIGN_IN = 'sign-in',
  SIGN_UP = 'sign-up',
  DEPOSIT_REQUESTS = 'deposit-requests',
  WITHDRAWAL_REQUESTS = 'withdrawal-requests',
  WALLETS = 'wallets',
  TRANSACTIONS = 'transactions',
  EXCHANGE = 'exchange',
  NOT_FOUND = '404'
}

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
  [AppRoutes.SIGN_IN]: {
    path: `/${AppRoutes.SIGN_IN}`,
    element: <SignInPage />,
    withoutLayout: true
  },
  [AppRoutes.SIGN_UP]: {
    path: `/${AppRoutes.SIGN_UP}`,
    element: <SignUpPage />,
    withoutLayout: true
  },
  [AppRoutes.DEPOSIT_REQUESTS]: {
    path: `/${AppRoutes.DEPOSIT_REQUESTS}`,
    element: <RequestsPage operationType={EOperation.deposit} />,
    authOnly: true,
  },
  [AppRoutes.WITHDRAWAL_REQUESTS]: {
    path: `/${AppRoutes.WITHDRAWAL_REQUESTS}`,
    element: <RequestsPage operationType={EOperation.withdrawal} />,
    authOnly: true,
  },
  [AppRoutes.WALLETS]: {
    path: `/${AppRoutes.WALLETS}`,
    element: <WalletsPage />,
    authOnly: true,
  },
  [AppRoutes.TRANSACTIONS]: {
    path: `/${AppRoutes.TRANSACTIONS}`,
    element: <TransactionsPage />,
    authOnly: true,
  },
  [AppRoutes.EXCHANGE]: {
    path: `/${AppRoutes.EXCHANGE}`,
    element: <ExchangePage />,
    authOnly: true
  },
  [AppRoutes.NOT_FOUND]: {
    path: '*',
    element: <div>Page not found</div>,
  }
}
