import { Button, Form, InputNumber, Modal, ModalProps, Select, Space } from "antd"
import { FC } from "react"
import { EInputErrorMessages } from "../../models/InputErrorMessages";
import useStores from "../../hooks/useStores";
import { usePopup } from "../../hooks/usePopup";
import { mapAssetsToSelectOptions } from "../../utils";

type FieldType = {
  baseAsset: { id: string, amount: number }
  quoteAsset: {id: string, amount: number}
  feeRate: number
};

type CompProps = {
  onSuccess: () => Promise<void>
} & ModalProps

export const CreatePoolModal: FC<CompProps> = ({onSuccess, ...rest}) => {
  const { signStore, configStore } = useStores()
  const assets = mapAssetsToSelectOptions(configStore.assets)
  const [form] = Form.useForm()
  const { message } = usePopup()

  const onFinish = async (values: FieldType) => {
    try {
      const { 
        baseAsset: { id: baseAssetId, amount: baseAssetAmount },
        quoteAsset: { id: quoteAssetId, amount: quoteAssetAmount}, 
        feeRate
      } = values
      const params = signStore.createPoolParams({
        quoteAssetId,
        baseAssetId,
        feeRate: feeRate * 1e6
      })
      const payments = [
        {
          assetId: quoteAssetId,
          amount: quoteAssetAmount * 1e8
        },
        {
          assetId: baseAssetId,
          amount: baseAssetAmount * 1e8
        },
      ]
      await signStore.callContract({ params, payments })
      message.info('Pool successfully created')
      await onSuccess()
      // setTimeout(async () => await fetchBalances(selectedWallet), 2000)
    } catch (e: any) {
      const text = e.response.data.errorMessage
      const error = text.slice(text.indexOf('[') + 1, text.indexOf(']') - 1)
      message.error(error)
    }
    form.resetFields()
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    form.resetFields()
    rest?.onCancel?.(e)
  };

  return <Modal
    {...rest}
    title='Create pool'
    onCancel={onCancel}
    footer={[
      <Button key='decline' onClick={onCancel} type="primary" danger>Cancel</Button>,
      <Button key='accept' type="primary" htmlType="submit" form="createPoolForm">Create</Button>
    ]}
  >
    <Form 
      id='createPoolForm'
      form={form} 
      onFinish={onFinish} 
      autoComplete="off"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 18 }}
      labelAlign="right"
    >
      <Form.Item label="Asset 1" required>
        <Space.Compact>
          <Form.Item<FieldType>
            noStyle
            name={['baseAsset', 'id']}
            rules={[{ required: true, message: EInputErrorMessages.REQUIRED }]}
          >
            <Select placeholder='Select asset' style={{ width: '50%' }} options={assets} />
          </Form.Item>
          <Form.Item<FieldType>
            noStyle
            name={['baseAsset', 'amount']}
            rules={[{ required: true, message: EInputErrorMessages.REQUIRED }]}
          >
            <InputNumber placeholder='Input amount' style={{ width: '50%' }} />
          </Form.Item>
        </Space.Compact>
      </Form.Item>
      <Form.Item label="Asset 2" required>
        <Space.Compact>
          <Form.Item<FieldType>
            noStyle
            name={['quoteAsset', 'id']}
            rules={[{ required: true, message: EInputErrorMessages.REQUIRED }]}
          >
            <Select placeholder='Select asset' style={{ width: '50%' }} options={assets} />
          </Form.Item>
          <Form.Item<FieldType>
            noStyle
            name={['quoteAsset', 'amount']}
            rules={[{ required: true, message: EInputErrorMessages.REQUIRED }]}
          >
            <InputNumber placeholder='Input amount' style={{ width: '50%' }}/>
          </Form.Item>
        </Space.Compact>
      </Form.Item>
      <Form.Item<FieldType>
        label="Fee rate"
        name={'feeRate'}
        rules={[{ required: true, message: EInputErrorMessages.REQUIRED }]}
      >
        <InputNumber 
          placeholder='Input fee %' 
          style={{ width: '50%' }} 
        />
      </Form.Item>
    </Form>

  </Modal>
}