import { Button, Form, List, Select, Space, Table } from "antd"
import { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import useStores from "../../hooks/useStores"
import { ITransaction } from "../../models/transaction"
import { getNormalizedParams, getNormalizedPayments, getSpacifiedNumber, trimAddress } from "../../utils"
import { useColumns } from "./useColumns"
import { usePopup } from "../../hooks/usePopup"
import { TUser } from "../../models/user"
import { EPoolAction, ERequestAction } from "../../models/actions"
import Paragraph from "antd/es/typography/Paragraph"
import styled from "styled-components"

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const getSelectValues = (addresses: string[]) => addresses.map(address => ({ value: address, label: address }))

const getSelectUsersValues = (users: TUser[]) => users.map(user => ({ 
  value: user.address, 
  label: `${trimAddress(user.address)} - ${user.companyName}`
}))

const poolActions = Object.values(EPoolAction)
const requestActions = Object.values(ERequestAction)

export const TransactionsPage = observer(() => {
  const columns = useColumns()
  const {authStore, api, configStore} = useStores()
  const [users, setUsers] = useState<TUser[]>([])
  const [transactions, setTransactions] = useState<ITransaction[] | undefined>()
  const { isAdmin } = authStore
  const { message } = usePopup()
  const [selectedWallet, setSelectedWallet] = useState(configStore.address)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    fetchTransactions(selectedWallet)
  }, [selectedWallet])

  const fetchTransactions = async (address: string) => {
    try {
      setIsLoading(true)
      const data = await api.getTransactionsByAddress(address)
      const txs = data.map(tx => {
        if (tx?.contractId === configStore.contractAddress) {
          const params = getNormalizedParams(tx.params)
          const payments = getNormalizedPayments(tx.payments)
          if (requestActions.includes(params['action'] as ERequestAction)) {
            return {
              id: tx.id,
              timestamp: tx.timestamp,
              sender: tx.sender,
              type: `${params['action']} ${(params['operation'] || '')}`,
              assetId: params['assetId'] ? configStore.getAssetNameById(params['assetId']) : '-',
              amount: params['amount'] ? getSpacifiedNumber(Number(params['amount']) / 1e8) : '-'
            }
          }
          if (poolActions.includes(params['action'] as EPoolAction)) {
            if (params['action'] === EPoolAction.SWAP) {
              const [[assetId, amount]] = Object.entries(payments)
              return {
                id: tx.id,
                sender: tx.sender,
                timestamp: tx.timestamp,
                type: params['action'],
                assetId: `${configStore.getAssetNameById(assetId)}`,
                amount: `${getSpacifiedNumber(Number(amount) / 1e8)}`
              }
            }
            return {
              id: tx.id,
              sender: tx.sender,
              timestamp: tx.timestamp,
              type: params['action'],
              assetId: `${configStore.getAssetNameById(params['baseAsset'])}/${configStore.getAssetNameById(params['quoteAsset'])}`,
              amount: `${getSpacifiedNumber(Number(payments[params['baseAsset']]) / 1e8)}/${getSpacifiedNumber(Number(payments[params['quoteAsset']]) / 1e8)}`
            }
          }
        }
        if (tx.type === 4 && configStore.assets[tx.assetId]) {
          return {
            id: tx.id,
            type: 'transfer',
            sender: tx.sender,
            timestamp: tx.timestamp,
            assetId: configStore.getAssetNameById(tx.assetId),
            amount: getSpacifiedNumber(Number(tx.amount) / 1e8),
          }
        }
      }).filter(Boolean) as ITransaction[]
      setTransactions(txs)
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const users = await api.getUsers()
        const filteredUsers = users.filter(user => user.address !== configStore.address)
        setUsers(filteredUsers)
      } catch (e) {
        message.error('Error on load users')
      }
    }
    if (isAdmin) {
      fetchUsers()
    }
    return () => message.destroy()
  }, [])

  return (
    <Space direction="vertical" style={{width: '100%'}}>
    <Button onClick={() => fetchTransactions(selectedWallet)}>Refresh</Button>
    <List 
      loading={isLoading}
      header={
        <HeaderWrapper>
          <Form.Item label={'Wallet'}>
            <Select
              defaultValue={configStore.address}
              style={{ width: 300 }}
              options={[
                {
                  label: 'My Wallet',
                  options: getSelectValues([configStore.address]),
                },
                ...(isAdmin && users.length ?
                  [{
                    label: 'Users wallets',
                    options: getSelectUsersValues(users)
                  }] : [])
              ]}
              onChange={setSelectedWallet}
            />
          </Form.Item>
          <Form.Item>
            <Paragraph copyable={{ text: selectedWallet }} style={{ margin: 0 }}>Copy</Paragraph>
          </Form.Item>
        </HeaderWrapper>
      }
    >
      <Table
        dataSource={transactions}
        columns={columns}
      />
    </List>
    </Space>
  )
})