import { Button, Form, InputNumber, Modal, ModalProps, Space, Typography } from "antd"
import { TPool } from "../../models/pool"
import { FC } from "react"
import { EInputErrorMessages } from "../../models/InputErrorMessages"
import useStores from "../../hooks/useStores"
import { usePopup } from "../../hooks/usePopup"
import { getSpacifiedNumber } from "../../utils"
import bn from 'bignumber.js'

type CompProps = {
  item: TPool,
  onSuccess: () => Promise<void>
} & ModalProps

type FieldType = {
  quoteAssetAmount: number
  baseAssetAmount: number
};

export const AddLiguidityModal: FC<CompProps> = ({ item, onSuccess, ...rest }) => {
  const { signStore, configStore } = useStores()
  const [form] = Form.useForm()
  const { message } = usePopup()

  const onFinish = async (values: FieldType) => {
    try {
      const { quoteAssetAmount, baseAssetAmount } = values
      const params = signStore.addLiguidityParams({
        poolId: item.id,
        baseAssetId: item.baseAssetId,
        quoteAssetId: item.quoteAssetId,
      })
      const payments = [
        {
          assetId: item.quoteAssetId,
          amount: Number(bn(quoteAssetAmount).shiftedBy(8).integerValue(bn.ROUND_FLOOR))
        },
        {
          assetId: item.baseAssetId,
          amount: Number(bn(baseAssetAmount).shiftedBy(8).integerValue(bn.ROUND_FLOOR))
        },
      ]
      await signStore.callContract({ params, payments })
      message.info('Liquidity successfully added')
      await onSuccess()
    } catch (e: any) {
      const text = e.response.data.errorMessage
      const error = text.slice(text.indexOf('[') + 1, text.indexOf(']') - 1)
      message.error(error)
    }
    form.resetFields()
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    form.resetFields()
    rest?.onCancel?.(e)
  };

  const onValuesChange = ({ baseAssetAmount, quoteAssetAmount }: FieldType) => {
    const rate = bn(item.baseAssetReserve).dividedBy(item.quoteAssetReserve)

    if (baseAssetAmount) {
      const amountLong = bn(baseAssetAmount).shiftedBy(8)
      const value = amountLong.dividedBy(rate)
      form.setFieldValue(
        "quoteAssetAmount",
        Number(value.shiftedBy(-8).decimalPlaces(8, bn.ROUND_FLOOR))
      )
    }

    if (quoteAssetAmount) {
      const amountLong = bn(quoteAssetAmount).shiftedBy(8)
      const value = amountLong.multipliedBy(rate)
      form.setFieldValue(
        "baseAssetAmount",
        Number(value.shiftedBy(-8).decimalPlaces(8, bn.ROUND_FLOOR))
      )
    }
  }

  return <Modal
    {...rest}
    title='Add Liquidity'
    onCancel={onCancel}
    footer={[
      <Button key='decline' onClick={onCancel} type="primary" danger>Cancel</Button>,
      <Button key='accept' type="primary" htmlType="submit" form="addLiquidityForm">Add Liquidity</Button>
    ]}
  >
    <Form
      id='addLiquidityForm'
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      onValuesChange={(value) => onValuesChange(value)}
    >
      <Space wrap>
        <Form.Item<FieldType>
          label={configStore.getAssetNameById(item.baseAssetId)}
          name={"baseAssetAmount"}
          rules={[{ required: true, message: EInputErrorMessages.REQUIRED }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Current Amount"
        >
          <Typography.Text className="ant-form-text" type="secondary">
            {getSpacifiedNumber(Number(item.baseAssetReserve) / 1e8)}
          </Typography.Text>
        </Form.Item>
      </Space>
      <Space wrap>
        <Form.Item<FieldType>
          label={configStore.getAssetNameById(item.quoteAssetId)}
          name={"quoteAssetAmount"}
          rules={[{ required: true, message: EInputErrorMessages.REQUIRED }]}
          dependencies={["baseAssetAmount"]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Current Amount"
        >
          <Typography.Text className="ant-form-text" type="secondary">
            {getSpacifiedNumber(Number(item.quoteAssetReserve) / 1e8)}
          </Typography.Text>
        </Form.Item>
      </Space>
    </Form>

  </Modal>
}
