import { Button, List, Space, Table } from "antd"
import { useEffect, useState } from "react"
import { CreatePoolModal } from "./CreatePoolModal"
import { TPool } from "../../models/pool"
import { usePopup } from "../../hooks/usePopup"
import useStores from "../../hooks/useStores"
import { useColumns } from "./useColumns"
import { AddLiguidityModal } from "./AddLiquidityModal"
import { SwapModal } from "./SwapModal"

export const ExchangePage = () => {
  const { api, configStore } = useStores()
  const [isCreatePoolModalOpen, setCreatePoolModalOpen] = useState(false)
  const [selectedPosition, setSelectedPosition] = useState<TPool | undefined>()
  const [isAddLiquidityModalVisible, setAddLiquidityModalVisible] = useState(false)
  const [isSwapPositionModalVisible, setSwapPositionModalVisible] = useState(false)
  const [pools, setPools] = useState<TPool[] | undefined>()
  const [isLoading, setLoading] = useState(false)
  const { message } = usePopup()

  const onAddLiquidityClick = (position: TPool) => {
    setSelectedPosition(position)
    setAddLiquidityModalVisible(true)
  }

  const onSwapClick = (position: TPool) => {
    setSelectedPosition(position)
    setSwapPositionModalVisible(true)
  }
  
  const columns = useColumns({ onAddLiquidityClick, onSwapClick })

  const getPools = async () => {
    try {
      setLoading(true)
      const data = await api.getKeysValuesByContractId(configStore.contractAddress, 'POOL_.*')
      const values = data.map(el => ({ id: el.key.split('_')[1], ...JSON.parse(el.value) }))
      setPools(values)
    } catch (e) {
      console.error(e);
      message.error('Error on load requests')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getPools()
  }, [])

  const onSuccess = async () => {
    setTimeout(async () => await getPools(), 2000)
  }

  return <>
    <List 
      header={<Space direction="horizontal">
        <Button onClick={getPools}>Refresh</Button>
        <Button type="primary" onClick={() => setCreatePoolModalOpen(true)}>Create pool</Button>
      </Space>}
    >
      <Table columns={columns} loading={isLoading} dataSource={pools} />
    </List> 
    <CreatePoolModal 
      open={isCreatePoolModalOpen} 
      onCancel={() => setCreatePoolModalOpen(false)} 
      onSuccess={async () => {
        setCreatePoolModalOpen(false)
        await onSuccess()
      }}
      />
    {selectedPosition && <>
      <AddLiguidityModal
        item={selectedPosition}
        open={isAddLiquidityModalVisible}
        onCancel={() => setAddLiquidityModalVisible(false)}
        onSuccess={async () => {
          setAddLiquidityModalVisible(false)
          await onSuccess()
        }}
      />
      <SwapModal
        item={selectedPosition}
        open={isSwapPositionModalVisible}
        onCancel={() => setSwapPositionModalVisible(false)}
        onSuccess={async () => {
          setSwapPositionModalVisible(false)
          await onSuccess()
        }}
      />
    </>
    }
  </>
}