import { FC, useEffect, useState } from "react"
import { RequestModal } from "./RequestModal"
import useStores from "../../hooks/useStores"
import { EOperation } from "../../models/operations"
import { IRequest, IRequestWithUserData } from "../../models/request"
import { TUser } from "../../models/user"
import { Button, Space, Table } from "antd"
import { usePopup } from "../../hooks/usePopup"
import { capitalize } from "../../utils"
import { useColumns } from "./useColumns"

type CompProps = {
  operationType: EOperation
}

export const RequestsPage: FC<CompProps> = ({ operationType }) => {
  const { api, configStore } = useStores()
  const [requests, setRequests] = useState<IRequestWithUserData[]>([])
  const [selectedItem, setSelectedItem] = useState<IRequestWithUserData | undefined>()
  const [isModalOpen, setModalOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const { message } = usePopup()
  const columns = useColumns()

  const onItemClick = (item: IRequestWithUserData) => {
    setSelectedItem(item)
    setModalOpen(true)
  }

  const getRequests = async (operation: EOperation) => {
    try {
      setLoading(true)
      const [data, usersData] = await Promise.all([
        api.getKeysValuesByContractId(configStore.contractAddress, 'REQUEST.*'),
        api.getUsers(),
      ])
      const values: IRequest[] = data.map(el => JSON.parse(el.value)).filter(el => el.type === operationType)
      const normalizedUsers = usersData.reduce((acc, cur) => ({
        ...acc,
        [cur.address]: cur
      }), {} as Record<string, TUser>)
      const requestsWithUserData: IRequestWithUserData[] = values.map(el => {
        const { firstname, lastname, id, ...rest } = normalizedUsers[el.targetAddress]
        return {
          ...el,
          ...rest,
          clientName: `${firstname} ${lastname}`,
        }
      })
      setRequests(requestsWithUserData)
    } catch (e) {
      console.error(e);
      message.error('Error on load requests')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getRequests(operationType)
  }, [operationType])

  const onFinish = async () => {
    setTimeout(async () => await getRequests(operationType), 2000)
  }

  return <Space direction="vertical" style={{width: '100%'}}>
    <Button onClick={() => getRequests(operationType)}>Refresh</Button>
    <Table
      loading={isLoading}
      dataSource={requests}
      columns={columns}
      onRow={(record) => {
        return {
          onClick: () => onItemClick(record)
        };
      }}
    />
    {selectedItem && 
      <RequestModal 
        title={`${capitalize(operationType)} Request`}
        item={selectedItem} 
        open={isModalOpen}
        onCancel={() => setModalOpen(false)}
        onFinish={onFinish}
      />
    }
  </Space>
}