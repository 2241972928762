import { Button, Modal, ModalProps } from "antd"
import { FC } from "react"
import styled from "styled-components"
import { ERequestStatus, IRequestWithUserData } from "../../models/request"
import useStores from "../../hooks/useStores"
import { getSpacifiedNumber } from "../../utils"

const ModalInner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
`

type CompProps = {
  item: IRequestWithUserData
  onFinish: () => Promise<void>
} & ModalProps

export const RequestModal: FC<CompProps> = ({ item, onFinish, ...rest}) => {
  const { signStore, configStore } = useStores()
  const isButtonsDisabled = item.status !== ERequestStatus.APPROVING

  const onDecline = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const params = signStore.declineRequestParams(item.id)
    await signStore.callContract({ params })
    rest?.onCancel?.(e)
    await onFinish()
  }

  const onAccept = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const params = signStore.acceptRequestParams(item.id)
    await signStore.callContract({ params })
    rest?.onCancel?.(e)
    await onFinish()
  }
  
  return (
    <Modal {...rest}
      footer={[
        <Button key='decline' type="primary" danger onClick={onDecline} disabled={isButtonsDisabled}>Decline</Button>,
        <Button key='accept' type="primary" onClick={onAccept} disabled={isButtonsDisabled}>Accept</Button>
      ]}
    >
      <ModalInner>
        <span>Request ID: {item.id}</span>
        <span>Client Address: {item.targetAddress}</span>
        <span>Asset: {configStore.getAssetNameById(item.assetId)}</span>
        <span>Amount: {getSpacifiedNumber(Number(item.amount) / 1e8)}</span>
        <span>Bank Account Number: {item.bankClientId}</span>
        <span>Company: {item.companyName}</span>
      </ModalInner>
    </Modal>
  )
}