import type { ColumnsType } from 'antd/es/table';
import useStores from "../../hooks/useStores";
import { ERequestStatus, IRequestWithUserData } from '../../models/request';
import dayjs from 'dayjs';
import { useSearchInput } from '../../hooks/useSearchFilter';

export const useColumns = () => {
  const { configStore } = useStores()
  const { getColumnSearchProps } = useSearchInput<IRequestWithUserData>()
  const columns: ColumnsType<IRequestWithUserData> = [
    {
      title: 'Time',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (v) => dayjs(v).format('DD.MM.YYYY hh:mm'),
      sorter: (a, b) => a.timestamp - b.timestamp,
      defaultSortOrder: "descend"
    },
    {
      title: 'Request ID',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps("id")
    },
    {
      title: 'Client Address',
      dataIndex: 'targetAddress',
      key: 'targetAddress',
      ...getColumnSearchProps("targetAddress")
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
      ...getColumnSearchProps("clientName")
    },
    {
      title: 'Company',
      dataIndex: 'companyName',
      key: 'companyName',
      ...getColumnSearchProps("companyName")
    },
    {
      title: 'Asset',
      dataIndex: 'assetId',
      key: 'assetId',
      render: (v, record) => {
        return record.assetId ? configStore.getAssetNameById(record.assetId) : '-'
      },
      filters: Object.keys(configStore.assets).map(el => ({ value: el, text: configStore.getAssetNameById(el) })),
      onFilter: (value, record) => record.assetId.indexOf(value.toString()) === 0
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (v, record) => {
        return record.amount ? Number(record.amount) / 1e8 : '-'
      },
      sorter: (a, b) => Number(a.amount) - Number(b.amount)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: Object.keys(ERequestStatus).map(el => ({ value: el, text: el })),
      onFilter: (value, record) => record.status.indexOf(value.toString()) === 0
    },
  ];

  return columns
}