import { AppRoutes } from "../../router/routeConfig";
import { TLink } from "./types";

export const clientMenuConfig: TLink[] = [
  {
    path: AppRoutes.WALLETS,
    text: 'My Wallet',
  },
  {
    path: AppRoutes.TRANSACTIONS,
    text: 'My Transactions',
  },
  {
    path: AppRoutes.EXCHANGE,
    text: 'Exchange',
  },
]