import { Layout as LayoutAntd } from "antd"
import { Sidebar } from "./components/sidebar/Sidebar"
import { Content } from "./components/Content"
import { Outlet } from "react-router-dom"

export const Layout = () => {
  return <LayoutAntd>
    <LayoutAntd hasSider>
      <Sidebar/>
      <Content>
        <Outlet />
      </Content>
    </LayoutAntd>
  </LayoutAntd>
}