import { ColumnsType } from "antd/es/table";
import { TPool } from "../../models/pool";
import { getSpacifiedNumber } from "../../utils";
import useStores from "../../hooks/useStores";
import { Button, Space } from "antd";

type Props = {
  onAddLiquidityClick: (record: TPool) => void,
  onSwapClick: (record: TPool) => void,
}

export const useColumns = (props: Props) => {
  const {configStore} = useStores()

  const columns: ColumnsType<TPool> = [
    {
      title: 'Pair',
      dataIndex: 'pair',
      key: 'pair',
      render: (_: any, record) => {
        return <>{`${configStore.getAssetNameById(record.baseAssetId)}/${configStore.getAssetNameById(record.quoteAssetId)}`}</>
      }
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      render: (_: any, record) => {
        return <>{`${getSpacifiedNumber(Number(record.baseAssetReserve) / 1e8)}/${getSpacifiedNumber(Number(record.quoteAssetReserve) / 1e8)}`}</>
      }
    },
    {
      title: 'Fee rate',
      dataIndex: 'feeRate',
      key: 'feeRate',
      render: (v) => `${Number(v) / 1e6}%`
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
    },
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      render: (_: any, record) => {
        return <Space wrap>
          <Button onClick={() => props.onAddLiquidityClick(record)}>Add Liquidity</Button>
          <Button onClick={() => props.onSwapClick(record)}>Swap</Button>
        </Space>
      }
    }
  ];
  return columns
}

