import { Button, Card, Form, Input } from "antd"
import { EInputErrorMessages } from "../../models/InputErrorMessages";
import useStores from "../../hooks/useStores";
import { AppRoutes } from "../../router/routeConfig";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { ITokenPair } from "../../api/ApiInterfaces";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { LinkWrap, PageWrapper, headStyle } from "./styles";
import { usePopup } from "../../hooks/usePopup";
import { capitalize } from "../../utils";

type FieldType = {
  email: string;
  password: string;
};

export const SignInPage = () => {
  const [form] = useForm()
  const { authStore, api, configStore } = useStores()
  const navigate = useNavigate()
  const { message } = usePopup()
  
  const onFinish = async ({ email, password}: FieldType) => {
    try {
      const tokens = await authStore.signIn(email, password)
      const {
        axios: refresherAxios,
      } = authStore.createRefresherAxios(tokens as ITokenPair, () => {
        authStore.logout()
        navigate(`/${AppRoutes.SIGN_IN}`)
      })
      api.setupApi(refresherAxios)
      authStore.setLoggedIn(true)
      await authStore.loadUserProfile()
      await configStore.init()
      await configStore.loadAssets()
      await authStore.loadUserData()
      navigate('/')
    } catch (e: any) {
      console.error(e)
      const error = e.response.data.errors
      message.error(capitalize(error.split('.').join(' ')))
    }
  };

  return (
    <PageWrapper>
      <Card 
        title="Digital Check System" 
        headStyle={headStyle}
      >
      <Form onFinish={onFinish} autoComplete="off" form={form}>
        <Form.Item<FieldType>
          style={{ width: 300 }}
          name="email"
          rules={[
            { type: 'email', message: 'The input is not valid E-mail' },
            { required: true, message: EInputErrorMessages.REQUIRED }
          ]}
        >
          <Input 
            prefix={<UserOutlined className="site-form-item-icon" />} 
            placeholder="Email"
          />
        </Form.Item>

        <Form.Item<FieldType>
          name="password"
          rules={[{ required: true, message: EInputErrorMessages.REQUIRED }]}
        >
          <Input.Password 
            prefix={<LockOutlined className="site-form-item-icon" />} 
            placeholder="Password"
          />
        </Form.Item>
        <Button htmlType="submit" style={{ width: '100%' }} type="primary">Sign in</Button>
        <LinkWrap>
            Or <Button type="link" onClick={() => navigate(`/${AppRoutes.SIGN_UP}`)}>register now!</Button>
        </LinkWrap>
      </Form>
      </Card>
    </PageWrapper>
  )
}