import { ContractParam, ContractPayment } from "@wavesenterprise/transactions-factory";

export const getNormalizedParams = (params: ContractParam[]) => {
  return params.reduce((acc, cur) => {
    return {
      ...acc,
    [cur.key]: cur.value.toString()
    }
  }, {} as Record<string, string>)
}

export const getNormalizedPayments = (params: ContractPayment[]) => {
  return params.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.assetId!]: cur.amount.toString()
    }
  }, {} as Record<string, string>)
}

export const mapAssetsToSelectOptions = (assets: Record<string, string>) => {
  return Object.entries(assets).map(([assetId, name]) => ({ label: name, value: assetId }))
}

export const capitalize = (str: string) => {
  return str[0].toUpperCase() + str.slice(1)
}

export const trimAddress = (address: string) => {
  return address.slice(0, 8) + '...' + address.slice(-6)
}

export const getSpacifiedNumber = (num: number) => {
  const parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  if (parts[1]) {
    parts[1] = parts[1].slice(0, 2)
  }
  return parts.join(".");
}