import type { ColumnsType } from 'antd/es/table';
import { ITransaction } from '../../models/transaction';
import dayjs from 'dayjs';
import { EPoolAction, ERequestAction } from '../../models/actions';
import { useSearchInput } from '../../hooks/useSearchFilter';
import { EOperation } from '../../models/operations';
import { capitalize } from '../../utils';


export const useColumns = () => {
  const { getColumnSearchProps } = useSearchInput<ITransaction>()
  const columns: ColumnsType<ITransaction> = [
    {
      title: 'Time',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (v) => dayjs(v).format('DD.MM.YYYY hh:mm'),
      sorter: (a, b) => a.timestamp - b.timestamp,
      defaultSortOrder: "descend"
    },
    {
      title: 'Type',
      dataIndex: 'type',
      filters: [
        {
          text: 'Requests',
          value: 'requests',
          children: [
            {
              text: 'Create Request',
              value: ERequestAction.CREATE_REQUEST,
              children: [
                {
                  text: capitalize(EOperation.deposit),
                  value: `${ERequestAction.CREATE_REQUEST} ${EOperation.deposit}`
                },
                {
                  text: capitalize(EOperation.withdrawal),
                  value: `${ERequestAction.CREATE_REQUEST} ${EOperation.withdrawal}`
                }
              ]
            },
            {
              text: 'Reject Request',
              value: ERequestAction.REJECT_REQUEST,
            },
            {
              text: 'Approve Request',
              value: ERequestAction.APPROVE_REQUEST,
            },
          ]
        },
        {
          text: 'Pools',
          value: 'pools',
          children: [
            {
              text: 'Create Pool',
              value: EPoolAction.CREATE_POOL,
            },
            {
              text: 'Add Liquidity',
              value: EPoolAction.ADD_LIQUIDITY,
            },
            {
              text: 'Swap',
              value: EPoolAction.SWAP,
            },
          ]
        },
        {
          text: 'Other',
          value: 'other',
          children: [
            {
              text: 'Transfer',
              value: 'transfer',
            }
          ]
        }
      ],
      onFilter: (value, record) => record.type.indexOf(value.toString()) === 0
    },
    {
      title: 'Client address',
      dataIndex: 'sender',
      key: 'sender',
    },
    {
      title: 'Asset',
      dataIndex: 'assetId',
      key: 'assetId',
      ...getColumnSearchProps('assetId')
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      ...getColumnSearchProps('amount')
    },
  ];

  return columns
}