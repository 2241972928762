import { Button, Form, List, Select, Space } from "antd"
import { useEffect, useState } from "react"
import { CreateRequestModal } from "./CreateRequestModal"
import { TransferModal } from "./TransferModal"
import useStores from "../../hooks/useStores"
import { EOperation } from "../../models/operations"
import { usePopup } from "../../hooks/usePopup"
import { TUser } from "../../models/user"
import { getSpacifiedNumber, trimAddress } from "../../utils"
import Paragraph from "antd/es/typography/Paragraph"
import styled from "styled-components"

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export type CreateRequstFieldType = {
  assetId: string
  amount: number,
  bankAccount: string,
};

export type TransferFieldType = {
  assetId: string,
  amount: number,
  recipient: string,
};

const getSelectValues = (wallets: string[]) => wallets.map(el => ({ value: el, label: el }))

const getSelectUsersValues = (users: TUser[]) => users.map(user => ({
  value: user.address,
  label: `${trimAddress(user.address)} - ${user.companyName}`
}))

export const WalletsPage = () => {
  const { authStore, configStore, signStore, api } = useStores()
  const {isAdmin} = authStore
  const [isCreateRequestModalOpen, setCreateRequestModalOpen] = useState(false)
  const [operation, setOperation] = useState<EOperation | undefined>()
  const [isTransferModalOpen, setTransferModalOpen] = useState(false)
  const { message } = usePopup()
  const [users, setUsers] = useState<TUser[]>([])
  const [balances, setBalances] = useState<Record<string, number>>({})
  const [selectedWallet, setSelectedWallet] = useState(configStore.address)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const users = await api.getUsers()
        const filteredUsers = users.filter(user => user.address !== configStore.address)
        setUsers(filteredUsers)
      } catch (e) {
        message.error('Error on load users')
      }
    }
    if (isAdmin) {
      fetchUsers()
    }
    return () => message.destroy()
  }, [])

  const fetchBalances = async (address: string) => {
    setIsLoading(true)
    const balances = await configStore.getAssetsBalancesByAddress(address)
    setBalances(balances)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchBalances(selectedWallet)
  }, [selectedWallet])

  const onFinishCreateRequest = async (values: CreateRequstFieldType) => {
    if (!operation) {
      return
    }
    try {
      const { amount, assetId } = values
      const longAmount = amount * 1e8
      const params = signStore.createRequestParams({
        amount: longAmount,
        operation,
        assetId
      })
      const payments = []
      if (operation === EOperation.withdrawal) {
        payments.push({
          assetId,
          amount: longAmount
        })
      }
      await signStore.callContract({ params, payments })
      setCreateRequestModalOpen(false)
      message.info('Request successfully sent')
      setTimeout(async () => await fetchBalances(selectedWallet), 2000)
    } catch (e: any) {
      const text = e.response.data.errorMessage
      const error = text.slice(text.indexOf('[') + 1, text.indexOf(']') - 1)
      message.error(error)
    }
  };

  const onFinishTransfer = async (values: TransferFieldType) => {
    try {
      const { amount, assetId, recipient } = values
      const longAmount = amount * 1e8
      await signStore.transfer({ amount: longAmount, assetId, recipient })
      setTransferModalOpen(false)
      message.info('Transfer successfully sent')
      setTimeout(async () => await fetchBalances(selectedWallet), 2000)
    } catch (e: any) {
      const text = e.response.data.errorMessage
      const error = text.slice(text.lastIndexOf(':') + 1)
      message.error(error)
    }
  };

  const onOpenRequestModal = (operation: EOperation) => {
    setCreateRequestModalOpen(true)
    setOperation(operation)
  }

  return <Space direction="vertical" style={{ width: '100%' }}>
      <Button onClick={() => fetchBalances(selectedWallet)}>Refresh</Button>
      <List
        loading={isLoading}
        style={{ width: '50%' }}
        header={
          <>
            <HeaderWrapper>
              <Form.Item label={'Wallet'}>
                <Select
                  defaultValue={configStore.address}
                  style={{ width: 300 }}
                  options={[
                    {
                      label: 'My Wallet',
                      options: getSelectValues([configStore.address]),
                    },
                    ...(isAdmin && users.length ? 
                      [{
                        label: 'Users wallets',
                        options: getSelectUsersValues(users)
                      }] : [])
                  ]}
                  onChange={setSelectedWallet}
                />
              </Form.Item>
              <Form.Item>
                <Paragraph copyable={{ text: selectedWallet }} style={{margin: 0}}>Copy</Paragraph>
              </Form.Item>
            </HeaderWrapper>
          </>
        }
        footer={selectedWallet === configStore.address &&
            <Form.Item>
              <Space wrap>
                <Button onClick={() => onOpenRequestModal(EOperation.withdrawal)} type="primary">Withdraw</Button>
                <Button onClick={() => onOpenRequestModal(EOperation.deposit)} type="primary">Deposit</Button>
                <Button onClick={() => setTransferModalOpen(true)} type="primary">Transfer</Button>
              </Space>
            </Form.Item>
        }
      >
        {Object.entries(balances).map(([assetId, balance]) => (
          <List.Item key={assetId}>{configStore.getAssetNameById(assetId)} {getSpacifiedNumber(balance / 1e8)}</List.Item>)
        )}
      </List>
        <CreateRequestModal 
          title={`Create ${operation} request`}
          open={isCreateRequestModalOpen} 
          onCancel={() => setCreateRequestModalOpen(false)} 
          onFinish={onFinishCreateRequest}
          balances={balances}
        />
        <TransferModal 
          open={isTransferModalOpen} 
          onCancel={() => setTransferModalOpen(false)} 
          onFinish={onFinishTransfer}
          balances={balances}
        />
  </Space>
}