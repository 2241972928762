import { Button, Card, Form, Input, Space } from "antd"
import styled from "styled-components"
import { EInputErrorMessages } from "../../models/InputErrorMessages";
import useStores from "../../hooks/useStores";
import { AppRoutes } from "../../router/routeConfig";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { ITokenPair } from "../../api/ApiInterfaces";
import { PageWrapper, headStyle } from "./styles";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { TCrateUser } from "../../models/user";
import { usePopup } from "../../hooks/usePopup";
import { capitalize } from "../../utils";
import { observer } from "mobx-react-lite";

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
`

type FieldType = TCrateUser & {
  email: string;
  password: string;
};

export const SignUpPage = observer(() => {
  const [form] = useForm<FieldType>()
  const { authStore, api, configStore } = useStores()
  const navigate = useNavigate()
  const { message } = usePopup()

  const onFinish = async (values: FieldType) => {
    try {
      const { email, password, ...rest } = values
      // await api.signUp(email, password)
      const tokens = await authStore.signIn(email, password)
      const {
        axios: refresherAxios,
      } = authStore.createRefresherAxios(tokens as ITokenPair, () => {
        authStore.logout()
        navigate(`/${AppRoutes.SIGN_IN}`)
      })
      api.setupApi(refresherAxios)
      const { address, publicKey } = await configStore.init()
      await api.createUser({ ...rest, address, publicKey })
      authStore.setLoggedIn(true)
      await authStore.loadUserProfile()
      await configStore.loadAssets()
      navigate('/')
    } catch (e: any) {
      const error = e.response.data?.errors[0] || e.response.data.message
      console.error(e)
      message.error(capitalize(error.split('.').join(' ')))
    }
  };

  return (
    <PageWrapper>
      <Card 
        title="Digital Check System" 
        headStyle={headStyle}
      >
      <Form onFinish={onFinish} autoComplete="off" form={form}>
        <Form.Item<FieldType>
          style={{ width: 300 }}
          name="email"
          rules={[
            { type: 'email', message: 'The input is not valid E-mail' },
            { required: true, message: EInputErrorMessages.REQUIRED }
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item<FieldType>
          name="password"
          rules={[{ required: true, message: EInputErrorMessages.REQUIRED }]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

          <Form.Item
            name="confirm"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Passwords do not match'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>

        <Form.Item<FieldType>
          name="firstname"
          rules={[{ required: true, message: EInputErrorMessages.REQUIRED }]}
        >
          <Input placeholder="Firstname" />
        </Form.Item>

        <Form.Item<FieldType>
          name="lastname"
          rules={[{ required: true, message: EInputErrorMessages.REQUIRED }]}
        >
          <Input placeholder="Lastname" />
        </Form.Item>

        <Form.Item<FieldType>
          name="companyName"
          rules={[{ required: true, message: EInputErrorMessages.REQUIRED }]}
        >
          <Input placeholder="Company" />
        </Form.Item>

        <Form.Item<FieldType>
          name="bankId"
          rules={[{ required: true, message: EInputErrorMessages.REQUIRED }]}
        >
          <Input placeholder="Bank Id" />
        </Form.Item>

        <Form.Item<FieldType>
          name="bankClientId"
          rules={[{ required: true, message: EInputErrorMessages.REQUIRED }]}
        >
          <Input placeholder="Bank Account" />
        </Form.Item>

        <ButtonsWrapper>
          <Button icon={<ArrowLeftOutlined />} onClick={() => navigate(`/${AppRoutes.SIGN_IN}`)} />
          <Button htmlType="submit" type="primary">Sign up</Button>
        </ButtonsWrapper>
      </Form>
      </Card>
    </PageWrapper>
  )
})