import { makeAutoObservable } from 'mobx'
import { Api } from '../api'
import { EOperation } from '../models/operations'
import { ContractParam, ContractPayment, TRANSACTIONS } from "@wavesenterprise/transactions-factory"
import ConfigStore from './ConfigStore';
import { ERequestAction, EPoolAction } from '../models/actions';
import bn from "bignumber.js";
import { TPool } from '../models/pool';
const fee = 100000000;

type TCallContractParams = {
  params: Array<ContractParam>, 
  payments?: Array<ContractPayment>
}
export default class SignStore {
  api: Api
  configStore: ConfigStore

  constructor(api: Api, configStore: ConfigStore){
    this.api = api
    this.configStore = configStore

    makeAutoObservable(this)
  }


  getKey(name: string) {
    return this.api.getKey(name)
  }
  
  declineRequestParams(requestId: string): Array<ContractParam> {
    return [
      {
        type: 'string',
        key: 'action',
        value: ERequestAction.REJECT_REQUEST
      },
      {
        type: 'string',
        key: 'requestId',
        value: requestId,
      },
    ]
  }

  acceptRequestParams(requestId: string): Array<ContractParam> {
    return [
      {
        type: 'string',
        key: 'action',
        value: ERequestAction.APPROVE_REQUEST
      },
      {
        type: 'string',
        key: 'requestId',
        value: requestId,
      },
    ]
  }

  createRequestParams(params: { amount: number, operation: EOperation, assetId: string }): Array<ContractParam> {
    const { amount, assetId, operation } = params
    return [
      {
        type: 'string',
        key: 'action',
        value: ERequestAction.CREATE_REQUEST
      },
      {
        type: 'integer',
        key: 'amount',
        value: amount,
      },
      {
        type: 'string',
        key: 'assetId',
        value: assetId
      },
      {
        type: 'string',
        key: 'targetAddress',
        value: this.configStore.address
      },
      {
        type: 'string',
        key: 'operation',
        value: operation
      },
    ]
  }

  createPoolParams(params: { quoteAssetId: string, baseAssetId: string, feeRate: number }): Array<ContractParam> {
    const { quoteAssetId, baseAssetId, feeRate } = params
    return [
      {
        type: 'string',
        key: 'action',
        value: EPoolAction.CREATE_POOL
      },
      {
        type: 'string',
        key: 'quoteAsset',
        value: quoteAssetId
      },
      {
        type: 'string',
        key: 'baseAsset',
        value: baseAssetId
      },
      {
        type: 'integer',
        key: 'feeRate',
        value: feeRate
      }
    ]
  }

  swapParams(params: {poolId: string}): Array<ContractParam> {
    const { poolId } = params
    return [
      {
        type: 'string',
        key: 'action',
        value: EPoolAction.SWAP
      },
      {
        type: 'string',
        key: 'poolId',
        value: poolId
      }
    ]
  }

  calculateSwap(pool: TPool, assetId: string, amount: bn) {
    const k = bn(pool.quoteAssetReserve).times(pool.baseAssetReserve)

    if (pool.quoteAssetId === assetId) {
      const quoteAfter = bn(pool.quoteAssetReserve).plus(amount)
      const baseAfter = k.div(quoteAfter).integerValue()

      const baseAssetAmount = bn(pool.baseAssetReserve).minus(baseAfter)

      return {
        poolAfter: {
          ...pool,
          quoteAssetReserve: quoteAfter.toString(),
          baseAssetReserve: baseAfter.toString()
        },
        assetOut: pool.baseAssetId,
        baseAssetAmount,
      }
    }

    const baseAfter = bn(pool.baseAssetReserve).plus(amount)
    const quoteAfter = k.div(baseAfter).integerValue()

    const baseAssetAmount = bn(pool.quoteAssetReserve).minus(quoteAfter)

    return {
        poolAfter: {
          ...pool,
          quoteAssetReserve: quoteAfter.toString(),
          baseAssetReserve: baseAfter.toString()
        },
        assetOut: pool.quoteAssetId,
        baseAssetAmount
      }
  }

  addLiguidityParams(params: { poolId: string, quoteAssetId: string, baseAssetId: string }): Array<ContractParam> {
    const { quoteAssetId, baseAssetId, poolId } = params
    return [
      {
        type: 'string',
        key: 'action',
        value: EPoolAction.ADD_LIQUIDITY
      },
      {
        type: 'string',
        key: 'poolId',
        value: poolId
      },
      {
        type: 'string',
        key: 'quoteAsset',
        value: quoteAssetId
      },
      {
        type: 'string',
        key: 'baseAsset',
        value: baseAssetId
      }
    ]
  }

  async callContract({ params, payments = [] }: TCallContractParams) {
    const tx = TRANSACTIONS.CallContract.V5({
      fee,
      contractId: this.configStore.contractAddress,
      // senderPublicKey: this.publicKey,
      contractVersion: 1,
      payments,
      params
    })
    const result = await this.broadcastTx(tx.getBody())
    return result
  }

  async transfer(params: { amount: number, assetId: string, recipient: string }) {
    const tx = TRANSACTIONS.Transfer.V3({
      senderPublicKey: this.configStore.publicKey,
      fee,
      ...params,
    })
    const result = await this.broadcast(tx.getBody())
    return result
  }

  broadcast(tx: any) {
    return this.api.broadcast(this.configStore.keyName, tx)
  }

  broadcastTx(tx: any) {
    return this.api.broadcastTx(this.configStore.keyName, tx)
  }
}
