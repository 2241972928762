import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from './router/AppRouter';
import { ConfigProvider, App as AntdApp } from 'antd';
import { Provider } from 'mobx-react'
import { Api } from './api';
import AuthStore from './stores/AuthStore';
import SignStore from './stores/SignStore';
import ConfigStore from './stores/ConfigStore';

const api = new Api()
const configStore = new ConfigStore(api)
const authStore = new AuthStore(api, configStore)
const signStore = new SignStore(api, configStore)

const stores = {
  api,
  authStore,
  configStore,
  signStore,
}


function App() {  
  return (
    <AntdApp>
      <Provider {...stores}>
        <ConfigProvider
          theme={{
            token: {
              // Seed Token
              colorPrimary: '#00A87A',
              borderRadius: 2,
            },
          }}
        >
        <BrowserRouter>
          <AppRouter/>
        </BrowserRouter>
        </ConfigProvider>
      </Provider>
    </AntdApp>
  );
}

export default App;
