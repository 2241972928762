import { AppRoutes } from "../../router/routeConfig";
import { TLink } from "./types";

export const adminMenuConfig: TLink[] = [
  {
    path: AppRoutes.DEPOSIT_REQUESTS, 
    text: 'Deposit Requests',
  },
  {
    path: AppRoutes.WITHDRAWAL_REQUESTS, 
    text: 'Withdrawal Requests',
  },
  {
    path: AppRoutes.WALLETS,
    text: 'Wallets',
  },
  {
    path: AppRoutes.TRANSACTIONS,
    text: 'Transactions',
  },
  {
    path: AppRoutes.EXCHANGE,
    text: 'Exchange',
  },
]